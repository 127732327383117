<template>
  <a href="javascript:void(0);" class="search-btn" @click="open = true">
    <img src="/images/icons/search.svg">
  </a>
  <teleport to="body">
    <div class="frame-modal-wrapper search-modal-wrapper" @click="open = false">
      <div class="modal-wrapper-holder" @click.stop.prevent="">
        <div class="frame-modal-header">
          <div class="name h3 no-uppercase">{{$static('search.title', 'Пошук продукту')}}</div>
          <a href="javascript:void(0);" class="close-cart" @click="open = false">
            <img src="/images/icons/close.svg">
          </a>
        </div>
        <div class="frame-modal-body">
          <div class="search-input">
            <img src="/images/icons/search.svg">
            <input type="text" id="query" class="form-control custom-input" ref="query" v-model="search" placeholder="...">
          </div>
          <div class="search-list">
            <ul>
              <li class="cart-item search-item" v-for="(item, index) in items" :key="index">
                <a href="javascript:void(0)" @click="openItem(item)">
                  <div class="item-holder">
                    <div class="item-info">
                      <div class="image bg" :style="{'background-image': $filters.getItemImage(item.preview)}"></div>
                      <div class="right-side">
                        <div class="name">{{ $dynamic(item, item.title, 'title') }}</div>
                        <div class="option-box">
                          <div class="price-box">
                            <div class="price-element price-old" v-if="item.price_old">
                              {{ $filters.money(item.price_old) }} {{$store.getters.getSettings.symbol}}
                            </div>
                            <div class="price-element">{{ $filters.money(item.price) }} {{ $store.getters.getSettings.symbol }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <div v-if="loading">{{$static('search.loader-load', 'loading')}}</div>
              <div v-if="noResult">{{$static('search.loader-nores', 'noresult')}}</div>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </teleport>
</template>
<script>
export default {
  name: 'SearchItem',
  data() {
    return {
      open    : false,
      search  : '',
      loading : false,
      noResult: false,
      timer   : undefined,
      perPage : 20,
      items   : []
    }
  },
  methods: {
    openItem(item) {
      this.open = false
      this.$router.push({name: 'Item', params: {category: this.categoryURL(item), id: item.id}})
    },
    getItemImage(item) {
      if (item.preview && item.preview.includes('https://')) {
        return item.preview
      }
      return item.preview ? `${window.location.protocol}//${window.location.host}${item.preview}` : ''
    },
    categoryURL(item) {
      // return {id: 1}
      // console.log(this.$store.getters.getCategories, item.category);
      let result = this.$store.getters.getCategories.find(i => i.id == item.category);
      if (!result) {
        return 'default'
      }
      return result.info.url
    },
    getQuery() {
      // console.log('query loading', this.loading)
      if (this.loading) {
        return
      }
      // console.log('get query', this.search)
      this.loading = true;
      let filter   = {perPage: this.perPage, page: this.page};
      if (this.search) {
        filter.search = this.search
      }
      fetch('/public/item/filter', {method: 'POST', body: JSON.stringify({filter: filter})})
          .then(response => response.json()).then(data => {
        this.loading = false;
        this.items   = data.result
        this.page    = data.page;
        this.total   = data.total;
        if (this.items.length === this.total) {
          this.more = false
        }
        this.noResult = !(Array.isArray(data.result) && data.result.length)
      }).catch(() => {
        this.loading = false;
      })
    }
  },
  watch  : {
    search(val) {
      // console.log('searching', val)
      this.noResult = false
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = undefined;
      }
      this.timer = setTimeout(() => {
        // console.log('call query', this.getQuery)
        this.getQuery()
      }, 800);
    },
    open(val) {
      if (val) {
        if (this.items.length === 0) {
          this.getQuery()
        }
        setTimeout(() => {
          this.$refs.query.focus()
        }, 100)
        window.$('.frame-modal-wrapper-bg').addClass('is-visible');
        window.$('.search-modal-wrapper').addClass('opened');
        window.$('body').addClass('no-scroll');
      } else {
        window.$('.frame-modal-wrapper-bg').removeClass('is-visible');
        window.$('.search-modal-wrapper').removeClass('opened');
        window.$('body').removeClass('no-scroll');
      }
    }
  }
}
</script>