<template>
    <li class="review-chain-section">
        <div class="review-block" :style="{
            filter: reviewNumber < moderatedReviewsNumber ? 'grayscale(100%)' : 'grayscale(0%)',
            opacity: reviewNumber < moderatedReviewsNumber ? 0.5 : 1
        }">
            <div class="review-block__header">
                <div class="review-block__header-part-wrapper left">
                    <div class="review-block__avatar">
                        <img v-if="review.body.reviewAuthorAvatar" :src="review.body.reviewAuthorAvatar" alt="">
                        <span v-else>{{ getReviewAuthorAvatar() }}</span>
                    </div>
                    <div class="review-block__username-wrapper">
                        <h3 class="review-block__username">{{ getReviewAuthorName() }}</h3>
                        <p class="review-block__status">{{ $static('reviews.already-buy', 'вже купив(ла)') }}</p>
                    </div>
                </div>
                <div class="review-block__header-part-wrapper right">
                    <div class="review-block__date">{{ getReviewDate(review) }}</div>
                    <div class="review-block__date-raiting-divider"></div>
                    <div class="raiting-container in-review-block">
                        <svg width="22" height="18" viewBox="0 0 22 18" class="raiting-star unfilled in-review-block"
                            v-for="starPosition in 5" :key="starPosition"> fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M17.497 17.9939L17.4335 17.498C17.3881 17.5038 17.3455 17.4967 17.3134 17.4829L17.3091 17.481L11.4778 15.0301C11.1747 14.9026 10.8253 14.9026 10.5221 15.0301L4.69089 17.481L4.68824 17.4822C4.64016 17.5027 4.58244 17.5055 4.53089 17.4905C4.47868 17.4752 4.45787 17.45 4.45269 17.4409C4.45229 17.4402 4.45197 17.4396 4.45171 17.4391L5.36731 11.9419C5.36734 11.9417 5.36738 11.9415 5.36742 11.9413C5.42771 11.5825 5.28507 11.2408 5.02724 11.0132L0.517289 7.0277L0.517294 7.02769L0.515239 7.02589C0.50745 7.01909 0.503388 7.01379 0.501409 7.01072C0.503461 7.00751 0.507863 7.00175 0.516599 6.99435L0.517376 6.99368C0.53532 6.97841 0.567874 6.96116 0.611961 6.95476L0.611962 6.95476L0.613903 6.95447L7.0019 6.00104C7.00205 6.00102 7.0022 6.001 7.00235 6.00098C7.32715 5.95307 7.63633 5.77376 7.81691 5.47975C7.81704 5.47954 7.81717 5.47933 7.8173 5.47912L10.8529 0.561154L10.8529 0.561159L10.8544 0.558618C10.8609 0.548031 10.884 0.52248 10.9375 0.508157C10.9903 0.494025 11.0477 0.498449 11.0943 0.519662L11.0951 0.520027C11.1277 0.534791 11.1414 0.551896 11.1454 0.558467L11.1454 0.558472L11.1471 0.561154L14.1827 5.47912C14.1827 5.47927 14.1828 5.47942 14.1829 5.47957C14.3635 5.7737 14.6728 5.95307 14.9977 6.00098C14.9978 6.001 14.9979 6.00102 14.9981 6.00104L21.3861 6.95448L21.3886 6.95484C21.4491 6.96356 21.4818 6.98981 21.4941 7.00475C21.4961 7.00709 21.4975 7.00906 21.4984 7.01062C21.4963 7.01403 21.4922 7.01938 21.4845 7.02605L21.4827 7.0277L16.9727 11.0132C16.9726 11.0133 16.9726 11.0133 16.9725 11.0134C16.7148 11.241 16.5723 11.5826 16.6325 11.9412C16.6326 11.9414 16.6326 11.9417 16.6326 11.9419L17.5483 17.4397C17.5473 17.4416 17.5454 17.4444 17.5422 17.4482C17.5288 17.4639 17.4944 17.4901 17.4329 17.498L17.497 17.9939Z"
                                stroke="#FFC828" fill="none" />
                        </svg>
                        <div class="raiting-container filled in-review-block" :style="{ width: getReviewRaiting(review) }">
                            <svg width="22" height="18" viewBox="0 0 22 18" fill="none"
                                class="raiting-star filled in-review-block" v-for="starPosition in 5" :key="starPosition"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.5726 0.298533L14.6089 5.21773C14.7027 5.3707 14.8735 5.47734 15.0708 5.50637L21.4599 6.45995C21.8125 6.51075 22.0499 6.79884 21.991 7.10311C21.969 7.21645 21.9069 7.32085 21.8138 7.40236L17.3036 11.3881C17.1626 11.5126 17.0966 11.6862 17.1257 11.8587L18.0418 17.3591C18.0942 17.6639 17.851 17.9481 17.497 17.9939C17.3664 18.0106 17.2331 17.9928 17.1153 17.942L11.284 15.491C11.1048 15.4157 10.8952 15.4157 10.716 15.491L4.88463 17.942C4.56437 18.0788 4.17618 17.9654 4.01831 17.6885C3.96008 17.5863 3.93873 17.4713 3.95814 17.3591L4.87428 11.8587C4.90339 11.6862 4.8374 11.5126 4.69636 11.3881L0.186191 7.40236C-0.0648409 7.18295 -0.0616059 6.82898 0.193308 6.61292C0.287769 6.53253 0.409403 6.47893 0.540095 6.45995L6.92912 5.50637C7.12645 5.47734 7.29726 5.3707 7.39107 5.21773L10.4274 0.298533C10.5937 0.025524 10.9851 -0.0794367 11.3015 0.0646051C11.4173 0.117085 11.5117 0.198597 11.5726 0.298533Z"
                                    fill="#FFC828" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div class="review-block__main-content">
                <p class="review-block__text">{{ getReviewText() }}</p>
                <div class="review-block__tag-container" v-if="review.body.tags.length > 0">
                    <div class="review-block__tag" v-for="(tagInReview, tagInReviewIndex) in review.body.tags"
                        :key="tagInReviewIndex">{{ tagInReview.toLowerCase() }}</div>
                </div>
                <div class="review-block__photo-container" v-if="review.body.photo.length > 0">
                    <img class="review-block__photo" :src="reviewPhoto" alt="фото в отзыве"
                        v-for="(reviewPhoto, reviewPhotoIndex) in review.body.photo " :key="reviewPhotoIndex">
                </div>
                <div class="review-block__answers-counter">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                        :fill="validAnswers ? '#2CAF14' : '#CCCCCC'">
                        <g clip-path="url(#clip0_1735_857)">
                            <path
                                d="M10.0725 0.808594H1.91581C1.063 0.808594 0.369141 1.50231 0.369141 2.35526V7.34697C0.369141 8.198 1.05997 8.89062 1.91032 8.89364V11.1588L5.16563 8.89364H10.0725C10.9253 8.89364 11.6191 8.19978 11.6191 7.34697V2.35526C11.6191 1.50231 10.9253 0.808594 10.0725 0.808594ZM10.96 7.34697C10.96 7.83627 10.5618 8.23446 10.0725 8.23446H4.95882L2.5695 9.8971V8.23446H1.91581C1.42644 8.23446 1.02832 7.83627 1.02832 7.34697V2.35526C1.02832 1.86582 1.42644 1.46777 1.91581 1.46777H10.0725C10.5618 1.46777 10.96 1.86582 10.96 2.35526V7.34697Z" />
                            <path d="M3.38086 3.1377H8.60884V3.79688H3.38086V3.1377Z" />
                            <path d="M3.38086 4.54395H8.60884V5.20312H3.38086V4.54395Z" />
                            <path d="M3.38086 5.9502H8.60884V6.60938H3.38086V5.9502Z" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1735_857">
                                <rect width="12" height="12" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span>Відповідей {{ validAnswers ? review?.body?.answers?.length : 0 }}</span>
                </div>
            </div>
        </div>
        <div class="review-block__answers-container" v-if="validAnswers">
            <div class="review-block review-block--answer"
                v-for="(reviewAnswer, reviewAnswerIndex) in review?.body?.answers" :key="reviewAnswerIndex">
                <div class="review-block__header">
                    <div class="review-block__header-part-wrapper left">
                        <div class="review-block__avatar">
                            <img v-if="isReviewAnswerAvatar" :src="this.$store.getters.getSettings?.logo" alt="аватар">
                            <span v-else>М</span>
                        </div>
                        <div class="review-block__username-wrapper">
                            <h3 class="review-block__username">{{ $static('review.username', 'Менеджер') }}</h3>
                            <p class="review-block__status">{{ $static('review.user-status', 'отдел контроля качества') }} </p>
                        </div>
                    </div>
                    <div class="review-block__header-part-wrapper right">
                        <div class="review-block__date">{{ getReviewAnswerDate(reviewAnswer) }}</div>
                    </div>
                </div>

                <div class="review-block__main-content">
                    <p class="review-block__text">{{ reviewAnswer?.text ?? "" }}</p>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
export default {
    name: 'ReviewItem',
    props: {
        review: {
            type: Object,
            required: true
        },
        reviewNumber: {
            type: Number,
            required: true
        },
        moderatedReviewsNumber: {
            type: Number,
            required: true
        }
    },
    computed: {
        validAnswers() {
            if (Array.isArray(this.review.body.answers) && this.review.body.answers[0] && this.review.body.answers[0]?.text) {
                return true;
            }
            return false;
        },
        isReviewAnswerAvatar() {
            if ((this.$store.getters.getSettings?.logo !== undefined || this.$store.getters.getSettings?.logo !== null) && typeof this.$store.getters.getSettings?.logo == "string" && this.$store.getters.getSettings.logo.length > 0) {
                return true
            }
            return false;
        }
    },
    methods: {
        getReviewAuthorAvatar() {
            if (typeof this.review?.body?.name === "string") {
                return this.review?.body?.name.charAt(0).toUpperCase();
            }
            else {
                return "А";
            }
            // тут не хватает функционала для выбора или картинки аватара иди если ее нет - первого символа имени как сейчас реализовано
        },
        getReviewAuthorName() {
            // const firstName = this.review?.body?.first_name.charAt(0).toUpperCase() + this.review?.body?.first_name.slice(1);
            // const lastName = this.review?.body?.last_name.charAt(0).toUpperCase() + this.review?.body?.last_name.slice(1);

            // return `${firstName} ${lastName}`;
            if (typeof this.review?.body?.name === "string") {
                return this.review?.body?.name.charAt(0).toUpperCase() + this.review?.body?.name.slice(1);
            }
            else {
                return "Анонiмний замовник";
            }
        },
        getReviewDate(rev) {
            if (rev?.body?.reviewTime) {
                const months = [
                    'січень', 'лютий', 'березень', 'квітень', 'травень', 'червень',
                    'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень'
                ];

                const dateFromBackend = new Date(rev.body.reviewTime * 1000);
                const day = dateFromBackend.getDate();
                const month = months[dateFromBackend.getMonth()];
                const year = dateFromBackend.getFullYear();

                if (year.toString().length !== 4) {
                    return '-';
                }
                return `${day} ${month} ${year}`;
            }

            return '-';
        },

        getReviewRaiting(rev) {
            return rev?.body?.reviewRaiting ? (rev.body.reviewRaiting * 20 + "%") : 0;
        },
        getReviewText() {
            return this.review?.body?.comment || "";
        },
        getReviewAnswerDate(revAnswer) {
            if (revAnswer.time) {
                const months = [
                    'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
                    'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
                ];

                const dateFromBackend = new Date(revAnswer.time * 1000);
                const day = dateFromBackend.getDate();
                const month = months[dateFromBackend.getMonth()];
                const year = dateFromBackend.getFullYear();

                if (year.toString().length !== 4) {
                    return '-';
                }
                return `${day} ${month} ${year}`;
            }

            return '-';
        },
    }
}
</script>

<style lang="scss" scoped>
.raiting-container {
    position: relative;
    display: flex;
    column-gap: 7px;
    margin-bottom: 8px;
    overflow: hidden;

    &.in-review-block {
        column-gap: 2px;
        margin-bottom: 0;
    }

    &.filled {
        position: absolute;
        top: 0;
        left: 0;
        width: 89%;
    }

    @media (max-width: 767px) {
        margin-bottom: -11px;
    }
}

.raiting-star {
    flex-grow: 0;
    flex-shrink: 0;
    width: 42px;
    height: 38.25px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.in-review-block {
        width: 14px;
        height: 12.75px;

        @media (max-width: 767px) {
            width: 13px;
            height: 11px;
        }
    }

    @media (max-width: 767px) {
        width: 22px;
        height: 18px;
    }
}

.review-chain-section {
    margin-bottom: 9px;
}

.review-block {
    position: relative;
    padding: 5px 0 11px 0;
    border-radius: 13px;
    border: 1px solid rgba(0, 0, 0, 0.10);


    &.review-block--answer {
        margin-top: 14px;
        margin-left: 77px;

        @media (max-width: 767px) {
            margin-top: 7px;
            margin-left: 37px;
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 27px;
            height: 81px;
            top: -15px;
            left: -27px;
            border-left: 1px solid #D9D9D9;
            border-bottom: 1px solid #D9D9D9;

            @media (max-width: 767px) {
                width: 17px;
                height: 71px;
                top: -9px;
                left: -17px;
            }
        }

        & .review-block__text {
            margin-right: 27px;
        }
    }

    @media (max-width: 767px) {
        padding-top: 6px;
    }
}

.review-block__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
    padding-bottom: 5px;
    border-bottom: 1px solid #D9D9D9;

    @media (max-width: 767px) {
        margin-bottom: 8px;
        padding-bottom: 6px;
    }
}

.review-block__header-part-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-grow: 0;
    flex-shrink: 0;

    @media (max-width: 767px) {
        gap: 5px;
    }

    &.left {
        margin-left: 12px;
    }

    &.right {
        gap: 3px;
        margin-right: 27px;

        @media (max-width: 767px) {
            flex-direction: column-reverse;
            margin-right: 12px;
        }
    }
}

.review-block__username-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.review-block__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #E9E9E9;
    overflow: hidden;

    & span {
        line-height: 1;
    }

    & img {
        width: 100%;
        /* object-fit: cover; */
    }

    @media (max-width: 767px) {
        width: 32px;
        height: 32px;
    }
}

.review-block__username {
    color: #171717;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.review-block__status {
    margin-top: -2px;
    margin-bottom: 0;
    color: #747474;
    font-size: 12px;
    line-height: normal;

    &::after {
        content: url(/public/images/icons/already-buy-status-in-review.svg);
      margin-left: 3px;
    }

    @media (max-width: 767px) {
        margin-top: 0;
    }
}

.review-block__date-raiting-divider {
    width: 1px;
    height: 13px;
    background-color: #D9D9D9;
    margin: 0 3px;

    @media (max-width: 767px) {
        display: none;
    }
}

.review-block__date {
    color: #747474;
    font-size: 12px;
    line-height: normal;
}

.review-block__main-content {
    padding: 0 27px;

    @media (max-width: 767px) {
        padding: 0 12px;
    }
}

.review-block__text {
    margin-right: 108px;
    margin-bottom: 8px;
    color: #353535;
    font-size: 16px;
    line-height: normal;
  min-height: 20px;

    @media (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 6px;
        font-size: 13px;
    }
}

.review-block__tag-container {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 108px;
    margin-bottom: 15px;


    @media (max-width: 767px) {
        margin-bottom: 12px;
    }
}

.review-block__tag {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 2px 7px 3px 11px;
    border: 0.5px solid #C5C5C5;
    border-radius: 3px;
    color: #747474;
    font-size: 14px;
    line-height: normal;

    @media (max-width: 767px) {
      padding: 2px 10px 3px 12px;
      font-size: 11px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 6px;
        transform: translate(0, -50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #2CAF14;
    }
}

.review-block__photo-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    margin-right: 108px;

    @media (max-width: 767px) {
        gap: 3px;
    }
}

.review-block__photo {
    width: 76px;
    height: 60px;
    border-radius: 3px;
    object-fit: cover;

    @media (max-width: 767px) {
        width: 39px;
        height: 31px;
    }
}

.review-block__answers-counter {
    position: absolute;
    bottom: 11px;
    right: 27px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    color: #353535;
    font-size: 16px;
    line-height: normal;
    text-decoration-line: underline;
    text-underline-offset: 2px;

    @media (max-width: 767px) {
      gap: 8px;
      right: 12px;
      font-size: 12px;
    }

    & svg {
      width: 16px;
      height: 16px;
    }
}
</style>