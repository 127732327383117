<template>
  <div class="order-wrapper wrapper">
    <div class="name h2">{{ $static('order.title', 'Оформлення замовлення:') }}</div>
    <div class="order-info panel" v-if="busked.length">
      <h3>{{ $static('order.info', 'Ваше замовлення') }}</h3>
      <div class="cart-item ">
        <div class="item-holder" v-for="(item, index) in busked" :key="index">
          <div class="item-info">
            <div class="image bg-cover" :style="{'background-image': $filters.getItemImage(item.preview)}">

            </div>
            <div class="right-side">
              <!--              <div class="name">{{item.title}}</div>-->
              <router-link :to="{ name: 'Item', params: { category: categoryURL(item), id: item.id } }">
                {{ item.title }}
              </router-link>
              <div class="info-labels">
                <span class="info-label">
                  {{ $static('order.count', 'Кількість:') }} <span>{{ item.count }}{{ $static('busked.nam', 'шт') }}</span>
                </span>
              </div>

              <div class="product-price">
                <div class="additional-line" v-if="item.price">
                  <div class="add-name">{{ $static('order.price', 'Ціна') }}</div>
                  <p class="value">{{ $filters.money(item.price) }} {{ $store.getters.getSettings.symbol }}</p>
                </div>
              </div>
              <div class="additional" v-if="item.additionSelect">
                <div class="additional-line" v-for="(m, index) in item.additionSelect" :key="index">
                  <div class="add-name">{{ m.name }}</div>
                  <p class="value" v-if="m.price">{{ m.price }} {{ $store.getters.getSettings.symbol }}</p>
                </div>
              </div>
              <div class="additional" v-if="item.additionUSelect">
                <div class="additional-line" v-for="(m, index) in item.additionUSelect" :key="index">
                  <div class="add-name">{{ m.name }}</div>
                  <p class="value" v-if="m.price">{{ m.price }} {{ m.old_price }} {{
                      $store.getters.getSettings.symbol
                    }}</p>
                </div>
              </div>

              <div class="subtotal">{{ item.count }} x {{ $filters.money(itemPrice(item)) }} {{
                  $store.getters.getSettings.symbol
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="personal-data">
      <h3>{{ $static('order.b1', '1. Персональні дані') }}</h3>
      <div class="panel">
        <div class="form-group" :class="{ 'error': errors['name'] }" v-if="buskedSettings.name">
          <label class="label-custom">
            {{ $static('order.name', 'Ім\'я') }}<span v-if="buskedSettings.name_require">*</span>
          </label>
          <input type="text" @focus="delError('name')" class="form-control custom-input"
                 :placeholder="$static('order.enter-name', 'Введіть ваше ім\'я')" v-model="order.name" name="name">
          <p class="help-text" v-for="(err, key) in errors['name']" :key="key">{{ err }}</p>
        </div>

        <div class="form-group" :class="{ 'error': errors['sname'] }" v-if="buskedSettings.sname">
          <label class="label-custom">
            {{ $static('order.surname', 'Прізвище') }}<span v-if="buskedSettings.sname_require">*</span>
          </label>
          <input type="text" @focus="delError('sname')" class="form-control custom-input"
                 :placeholder="$static('order.enter-surname', 'Введіть ваше прізвище')" v-model="order.surname"
                 name="second_name">
          <p class="help-text" v-for="(err, key) in errors['sname']" :key="key">{{ err }}</p>
        </div>


        <div class="form-group" :class="{ 'error': errors['phone'] }" v-if="buskedSettings.phone || !isWebApp">
          <label class="label-custom">
            {{ $static('order.phone', 'Телефон') }}<span v-if="buskedSettings.phone_require || !isWebApp">*</span>
          </label>
          <input type="text" @focus="delError('phone')" class="form-control custom-input"
                 :placeholder="$static('order.enter-phone', '+380998787858')" v-model="order.phone" name="phone">
          <p class="help-text" v-for="(err, key) in errors['phone']" :key="key">{{ err }}</p>
        </div>


        <div class="form-group" :class="{ 'error': errors['email'] }" v-if="buskedSettings.email">
          <label class="label-custom">
            {{ $static('order.email', 'Email') }}<span v-if="buskedSettings.email_require">*</span>
          </label>
          <input type="text" @focus="delError('email')" class="form-control custom-input"
                 :placeholder="$static('order.enter-email', 'Введіть ваш email')" v-model="order.email" name="email">
          <p class="help-text" v-for="(err, key) in errors['email']" :key="key">{{ err }}</p>
        </div>
      </div>
    </div>

    <div class="payment-data">
      <h3>{{ $static('order.b3', '2. Оплата') }}</h3>
      <div class="option-group">
        <div class="empty-text" v-if="payment.length === 0">
          <p>{{ $static('order.empty-pay', 'У вас немає активованих способів оплати') }}</p>
        </div>
        <div class="option-item" v-for="(pay, index) in payment" :key="index" @click="order.pay_type = pay.id"
             :class="{ 'active': order.pay_type === pay.id }">
          <div class='option-item-body'>
            <p>{{ $dynamic(pay.manifest, pay.manifest.title, 'title') }}</p>
          </div>
        </div>
      </div>

    </div>
    <div class="bonus-panel" v-if="maxBonus > 0">
      <div class="form-group">
        <label class="label-custom">
          {{ $static('order.bonus-payment', 'Розрахуватись бонусами:') }}
          <span v-if="settings?.max_bonus && settings?.max_bonus !== 100">
            {{ $static('order.bonus-no-more', 'не більше ніж ') }}
            {{ settings?.max_bonus }}%
            {{ $static('order.bonus-procent', 'від суми') }}</span>
        </label>
        <div class="input-btn-group">
          <button class="default-btn" @click="setBonusMax">
            <span class="btn-text">Max {{ maxBonus }}</span>
          </button>
          <input type="text" class="form-control custom-input" :placeholder="0" v-model.number="order.bonus_discount">
        </div>
      </div>
      <!--      <div class="d-none">-->
      <!--        <label class="label-custom">-->
      <!--          {{ $static('order.bonus-payment', 'Розрахуватись бонусами:') }} <span>{{-->
      <!--            $static('order.must-login', 'Необхідна авторизація')-->
      <!--          }}</span>-->
      <!--        </label>-->
      <!--      </div>-->
    </div>

    <!-- TODO .................................. -->
    <OrderDelivery v-if="!deliveryDisable" :delivery="delivery" :errors="errors" @errorCleaningFocus="delError"/>
    <!-- TODO .................................. -->

    <div class="comment-data" :class="{ 'error': errors['comment'] }" v-if="buskedSettings.comment">
      <h3>{{ $static('order.b4', '4. Коментар') }}</h3><span v-if="buskedSettings.comment_require">*</span>
      <div class="comment-group	form-group">
        <textarea @focus="delError('comment')" class="form-control custom-input"
                  :placeholder="$static('order.comment', 'Введіть текст коментару')" v-model="order.comment"></textarea>
        <p class="help-text" v-for="(err, key) in errors['comment']" :key="key">{{ err }}</p>
      </div>
    </div>

    <div class="order-total panel" v-if="min_price && total < min_price" :class="{'error':errors.min_price}">
      <div class="name">{{ $static('order.min_price', 'Мінімальна сумма замовлення') }}</div>
      <div class="value">
        <div class="price-element">{{ $filters.money(min_price) }} {{ $store.getters.getSettings.symbol }}</div>
      </div>
    </div>

    <div class="order-total panel" v-else-if="busked.length">
      <div class="name">{{ $static('order.total', 'Всього до сплати') }}:</div>
      <div class="value">
        <div class="price-element">{{ $filters.money(total) }} {{ $store.getters.getSettings.symbol }}</div>
      </div>
    </div>

    <div class="order-buttons" :class="{'disabled': total > min_price}">
      <button class="default-btn" @click.prevent.stop="makeOrder()">
        <span class="btn-text" v-if="isDeliveryCustom">{{ $static('order.makeOrder', 'Замовити') }}</span>
        <span class="btn-text" v-else>{{ $static('order.pay', 'Сплатити') }}</span>
      </button>

      <div class="link-holder">
        <router-link :to="{ name: 'home' }" class="back-link">
          <img src="images/icons/left.svg" alt="">
          <span class="text">{{ $static('order.back', 'Назад у магазин') }}</span>
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
import OrderDelivery from '../components/OrderDelivery';

export default {
  name      : 'Order',
  components: {OrderDelivery},
  data() {
    return {
      errors  : {},
      delivery: [],
      payment : [],
      order   : {
        name          : '',
        surname       : '',
        phone         : '',
        pay_type      : undefined,
        comment       : '',
        bonus_discount: 0
      }
    }
  },
  mounted() {
    this.$OrderPage();
    document.title = 'Order';
    fetch('/public/plugin').then(response => response.json()).then(data => {
      let defaultPayment  = undefined;
      let defaultDelivery = undefined;

      data.forEach(x => {
        this[x.type] = x.list;
        if (x.list.length) {
          let defaultValue = x.list[0];
          switch (x.type) {
          case 'payment':
            defaultPayment = defaultValue.id;
            break;
          case 'delivery':
            defaultDelivery = defaultValue;
            break
          }
        }
      });
      if (defaultDelivery) {
        this.$store.commit('setDeliveryInfo', defaultDelivery);
      }
      if (defaultPayment) {
        this.order.pay_type = defaultPayment
      }
    });

    if (this.deliveryOptions.length) {
      this.delivery.type = this.deliveryOptions[0]
    }
    // if (this.payment.length) {
    //   let payDefault = this.payment.find(i => i.settings.default);
    //   if (payDefault) {
    //     this.order.pay_type = payDefault
    //   } else {
    //     this.order.pay_type = this.payment[0]
    //   }
    // }
  },
  computed: {
    deliveryInfo() {
      return this.$store.getters.getDeliveryInfo;
    },
    deliveryOptions() {
      return this.$store.getters.getDeliveryOptions
    },
    userInfo() {
      return this.$store.getters.getUserInfo
    },
    account() {
      return this.$store.getters.getAccount
    },
    bonus() {
      return this.account ? this.account.bonus : 0
    },
    maxDiscountByPrice() {
      return this.total * (this.settings?.max_bonus ? (Math.min(Math.max(this.settings.max_bonus, 0), 100) / 100) : 0)
    },
    maxBonus() {
      return this.bonus ? Math.floor(Math.min(this.maxDiscountByPrice, this.bonus)) : 0
    },
    isWebApp() {
      return this.$store.getters.isWebApp
    },

    isDeliveryCustom() {
      if (this.order && this.order.pay_type) {
        let d = this.payment.find(x => x.id === this.order.pay_type)
        if (d && d.name === 'custom') {
          return true
        }
      }
      return false
    },
    buskedSettings() {
      return this.settings && this.settings.busked ? this.settings.busked : {}
    },
    min_price() {
      return this.buskedSettings.min_price
    },
    anonymous() {
      return this.buskedSettings.anonymous
    },
    deliveryDisable() {
      return this.buskedSettings.delivery
    },
    commentDisable() {
      return this.buskedSettings.comment
    },
    busked() {
      return this.$store.getters.getBusked
    },
    total() {
      return this.busked.reduce((c, item) => c + this.itemPrice(item), 0)
    },
    settings() {
      return this.$store.getters.getSettings
    },
    BOT_NAME() {
      return this.settings.BOT_NAME ? this.settings.BOT_NAME : false
    }
  },
  methods : {
    setBonusMax() {
      this.order.bonus_discount = this.maxBonus
    },
    categoryURL(item) {
      let result = this.$store.getters.getCategories.find(i => i.id == item.category);
      if (!result) {
        return 'default'
      }
      return result.info.url
    },
    telegramLoadedCallbackFunc() {
      // console.log('script is loaded')
      this.isLoaded = true
    },
    yourCallbackFunction(user) {
      // console.log(user);
      localStorage.setItem('user_info', JSON.stringify(user));
      this.makeOrder(user);
      // fetch('/auth/login', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify(user)
      // })
      //   .then(response => response.json())
      //   .then(json => {
      //     localStorage.setItem('user_info', JSON.stringify(user));
      //     window.user_info = user;
      //     if (json.error === undefined) {
      //       if (json.init) {
      //         this.$router.push({name: 'Init'})
      //       } else {
      //         this.$router.push({name: 'Dashboard'})
      //       }
      //     }
      //   })
    },

    getItemImage(item) {
      if (item.preview && item.preview.includes('https://')) {
        return item.preview
      }
      return item.preview ? `${window.location.protocol}//${window.location.host}${item.preview}` : ''
    },
    addition_price(item) {
      return item.additionSelect ? item.additionSelect.reduce((c, value) => value.price ? c + value.price : c, 0) : 0
    },
    addition_Uprice(item) {
      return item.additionUSelect ? item.additionUSelect.reduce((c, value) => value.price ? c + value.price : c, 0) : 0
    },
    showError(title, str) {
      window.$('#error').addClass('is-visible')
      window.$('#error .h2').html(title)
      window.$('#error p').html(str)
    },
    itemPrice(item) {
      return (item.price * item.count) + this.addition_price(item) + this.addition_Uprice(item)
    },
    delBasked(item, index) {
      this.$store.commit('delBasked', index)
    },
    makeOrder(user) {
      let valid = this.validate();
      if (!valid) {
        if (window.scrollTarget) {
          window.scrollTo({
                            top     : window.scrollTarget.offsetTop,
                            behavior: 'smooth'
                          });
          window.scrollTarget = undefined
        } else {
          window.scrollTo(0, 0);
        }
        return
      }

      let order = Object.assign({}, this.order, {
        delivery: this.$store.getters.getDeliveryInfo,
        busked  : this.busked.map(i => {
          return {
            id      : i.id,
            count   : i.count,
            add     : i.additionSelect.map(i => i.id),
            u_select: i.additionUSelect
          }
        })
      });

      if (order.delivery.type && order?.delivery?.type?.name) {
        order.delivery.type = order.delivery.type.name
      }

      if (order.delivery.warehouse && order?.delivery?.warehouse?.Description) {
        order.delivery.warehouse = order.delivery.warehouse.Description
      }

      if (order.delivery.address && order?.delivery?.address?.Description) {
        order.delivery.address = order.delivery.address.Description
      }

      if (order.delivery.city && order?.delivery?.city?.Description) {
        order.delivery.city = order.delivery.city.Description
      }

      if (order.pay_type && order.pay_type.name) {
        order.pay_type = order.pay_type.name
      }

      if (this.deliveryDisable) {
        order.delivery = undefined
      }

      this.$Purchase(this.busked)

      if (user) {
        order.user = user
      }
      if (this.userInfo) {
        order.user = this.userInfo
      }
      let headers   = {};
      let initCache = localStorage.getItem('initData');
      if (initCache && initCache.length) {
        headers.initData = initCache;
      }
      // console.log(order);
      return fetch(
          '/public/order',
          {
            method : 'POST',
            body   : JSON.stringify(order),
            headers: headers
          }
      ).then(response => response.json()).then(data => {
        if (data.error) {
          window.$('#error').addClass('is-visible');
          return
        }
        window.$('#thx').addClass('is-visible');
        if (initCache) {
          setTimeout(() => {
            window.Telegram.WebApp.close();
          }, 1000);
        }

        this.$store.commit('clearBasked');
        if (data.link) {
          setTimeout(() => {
            window.location.href = data.link;
          }, 1000);
        } else {
          setTimeout(() => {
            window.$('#thx').removeClass('is-visible');
            this.$router.push({name: 'home'});
          }, 3000);
        }

        return []
      }).catch((err) => {
        console.error(err);
        window.$('#thx').removeClass('is-visible');
        window.$('#errorModal').removeClass('is-visible');
      })

    },
    delError(key) {
      delete this.errors[key]
    },
    validate() {
      this.errors = {};
      if (this.min_price && this.total < this.min_price) {
        this.showError('', this.$static('order.min_price', 'Мінімальна сумма замовлення') +
            `: ${this.$filters.money(this.min_price)} ${this.$store.getters.getSettings.symbol}`)
        window.scrollTarget   = document.querySelector('.order-total')
        this.errors.min_price = true;
        return false
      }
      window.scrollTarget = document.querySelector('.personal-data')
      if (this.buskedSettings.name && this.buskedSettings.name_require) {
        if (this.order.name.length < 3) {
          this.errors.name = [this.$static('order.name-require-error', 'Ім\'я обов\'язкове')];
          this.showError('', this.$static('order.name-require-error', 'Ім\'я обов\'язкове'))
          return false
        }
      }

      //ToDo: check is telegram exist - if not then phone require
      if ((this.buskedSettings.phone && this.buskedSettings.phone_require || this.account === undefined)) {
        if (this.order.phone.length < 8) {
          this.errors.phone = [this.$static('order.phone-error', 'Введіть коректний номер')];
          this.showError('', this.$static('order.phone-error', 'Введіть коректний номер'))
          return false
        }
      }
      if (this.buskedSettings.sname && this.buskedSettings.sname_require) {
        if (this.order.phone.length < 8) {
          this.errors.sname = [this.$static('order.name-require-error', 'Ім\'я обов\'язкове')];
          this.showError('', this.$static('order.name-require-error', 'Ім\'я обов\'язкове'))
          return false
        }
      }
      if (this.buskedSettings.comment && this.buskedSettings.comment_require) {
        if (this.order.comment.length < 3) {
          this.errors.comment = [this.$static('order.comment-require-error', 'Коментар обов\'язковий')];
          this.showError('', this.$static('order.comment-require-error', 'Коментар обов\'язковий'))
          return false
        }
      }

      if (!this.buskedSettings.delivery) {
        window.scrollTarget = document.querySelector('.delivery-data')
        if (this.deliveryInfo.name === 'Nova Poshta') {
          if (this.deliveryInfo.city === undefined) {
            this.errors.delivery_city = [this.$static('order.delivery-city-error', 'Місто обов\'язкове')];
          this.showError('', this.$static('order.delivery-city-error', 'Місто обов\'язкове'))
            return false
          }
          if (this.deliveryInfo.city.Description === undefined) {
            this.errors.delivery_city = [this.$static('order.delivery-city-error2', 'Оберіть місто зі списку')];
          this.showError('', this.$static('order.delivery-city-error2', 'Оберіть місто зі списку'))
            return false
          }
          if (this.deliveryInfo.warehouse === undefined) {
            this.errors.delivery_warehouse = [this.$static('order.delivery-warehouse-error', 'Відділення обов\'язкове')];
          this.showError('', this.$static('order.delivery-warehouse-error', 'Відділення обов\'язкове'))
            return false
          }
          if (this.deliveryInfo.warehouse.Description === undefined) {
            this.errors.delivery_warehouse = [this.$static('order.delivery-warehouse-error2', 'Оберіть відділення зі списку')];
          this.showError('', this.$static('order.delivery-warehouse-error2', 'Оберіть відділення зі списку'))
            return false
          }
        } else if (this.deliveryInfo.name === 'Nova Poshta Curier') {
          if (this.deliveryInfo.city === undefined) {
            this.errors.delivery_city = [this.$static('order.delivery-city-error', 'Місто обов\'язкове')];
          this.showError('', this.$static('order.delivery-city-error', 'Місто обов\'язкове'))
            return false
          }
          if (this.deliveryInfo.city.Description === undefined) {
            this.errors.delivery_city = [this.$static('order.delivery-city-error2', 'Оберіть місто зі списку')];
          this.showError('', this.$static('order.delivery-city-error2', 'Оберіть місто зі списку'))
            return false
          }
          if (this.deliveryInfo.address === undefined) {
            this.errors.delivery_address = [this.$static('order.delivery-address-error', 'Адреса обов\'язкове')];
          this.showError('', this.$static('order.delivery-address-error', 'Адреса обов\'язкове'))
            return false
          }
          if (this.deliveryInfo.address.Description === undefined) {
            this.errors.delivery_address = [this.$static('order.delivery-address-error2', 'Оберіть адресу зі списку')];
          this.showError('', this.$static('order.delivery-address-error2', 'Оберіть адресу зі списку'))
            return false
          }

        } else {
          if (this.deliveryInfo.type && this.deliveryInfo.type.settings) {
            if (this.deliveryInfo.type.settings.city && (this.deliveryInfo.city === undefined || this.deliveryInfo.city.length < 3)) {
              this.errors.delivery_city = [this.$static('order.delivery-city-error', 'Місто обов\'язкове')];
          this.showError('', this.$static('order.delivery-city-error', 'Місто обов\'язкове'))
              return false
            }

            if (this.deliveryInfo.type.settings.address && (this.deliveryInfo.address === undefined || this.deliveryInfo.address.length < 3)) {
              this.errors.delivery_address = [this.$static('order.delivery-address-error', 'Адреса обов\'язкова')];
          this.showError('', this.$static('order.delivery-address-error', 'Адреса обов\'язкова'))
              return false
            }
            if (this.deliveryInfo.type.settings.warehouse && (this.deliveryInfo.warehouse === undefined || this.deliveryInfo.warehouse.length < 3)) {
              this.errors.delivery_warehouse = [this.$static('order.delivery-warehouse-error', 'Відділення обов\'язкове')];
          this.showError('', this.$static('order.delivery-warehouse-error', 'Відділення обов\'язкове'))
              return false
            }
          }
        }
      }

      return true;
    }
  },
  watch   : {
    deliveryOptions(val) {
      if (val.length) {
        this.delivery.type = val[0]
      }
    }
  }
  // payment(val) {
  //   if (val.length) {
  //     let payDefault = val.find(i => i.settings.default);
  //     if (payDefault) {
  //       this.order.pay_type = payDefault
  //     } else {
  //       this.order.pay_type = val[0]
  //     }
  //   }
  // }

}

</script>

<style scoped>
.order-total.error {
  color       : red;
  font-weight : bold;
}
</style>
