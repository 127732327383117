<template>
    <div class="delivery-data">
        <h3>{{ $static('order.b2', '3. Доставка') }}</h3>

        <div class="delivery-type">
            <div class="selector-body">
                <div class="option-group">
                    <div class="empty-text" v-if="delivery.length === 0">
                        <p>{{ $static('order.empty-delivery', 'У вас немає активованих способів доставки') }}</p>
                    </div>
                    <div class="option-item" v-for="(deli, index) in  delivery " :key="index"
                        @click="this.$store.commit('setDeliveryInfo', deli)"
                        :class="{ 'active': deliveryInfo.id === deli.id }">
                        <div class='option-item-body'>
                            <p>{{ $dynamic(deli.manifest, deli.manifest.title, 'title') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="deliveryInfo && deliveryInfo.name === 'Nova Poshta'">
            <label class="label-custom">
                {{ $static('order.city', 'Місто') }}<span>*</span>
            </label>
            <div class="form-row row">
                <div class="form-group" :class="{ 'error': errors['delivery_city'] }" style="width: 100%">
                    <SearchSelect :search="areaCity" @change="updateCityInDeliveryInfo"
                        @errorCleaningFocus="$emit('errorCleaningFocus', 'delivery_city')" />
                    <p class="help-text" v-for="( err, key ) in  errors['delivery_city']" :key="key">{{ err }}</p>
                </div>
            </div>

            <label class="label-custom">
                {{ $static('order.warehouse', 'Відділення') }}<span>*</span>
            </label>
            <div class="form-row row" :class="{ 'disabled': deliveryInfo?.city === undefined }">
                <div class="form-group" :class="{ 'error': errors['delivery_warehouse'] }" style="width: 100%">
                    <SearchSelect :search="areaWarehouse" @change="updateWarehouseInDeliveryInfo"
                        @errorCleaningFocus="$emit('errorCleaningFocus', 'delivery_warehouse')" />
                    <p class="help-text" v-for="( err, key ) in  errors['delivery_warehouse']" :key="key">{{ err }}</p>
                </div>
            </div>
        </template>

        <template v-if="deliveryInfo && deliveryInfo.name === 'Nova Poshta Curier'">
            <label class="label-custom">
                {{ $static('order.city', 'Місто') }}<span>*</span>
            </label>
            <div class="form-row row">
                <div class="form-group" :class="{ 'error': errors['delivery_city'] }" style="width: 100%">
                    <SearchSelect :search="areaCity" @change="updateCityInDeliveryInfo"
                        @errorCleaningFocus="$emit('errorCleaningFocus', 'delivery_city')" />
                    <p class="help-text" v-for="( err, key ) in  errors['delivery_city']" :key="key">{{ err }}</p>
                </div>
            </div>

            <label class="label-custom">
                {{ $static('order.addr', 'Адреса') }}<span>*</span>
            </label>
            <div class="form-row row" :class="{ 'disabled': deliveryInfo.city === undefined }">
                <div class="form-group" :class="{ 'error': errors['delivery_address'] }" style="width: 100%">
                    <SearchSelect :preload="true" :search="areaStreet" @change="updateAddressInDeliveryInfo"
                        @errorCleaningFocus="$emit('errorCleaningFocus', 'delivery_address')" />
                    <p class="help-text" v-for="( err, key ) in  errors['delivery_address']" :key="key">{{ err }}</p>
                </div>
            </div>

            <label class="label-custom">
                {{ $static('order.num', 'Номер Будинку') }}<span>*</span>
            </label>
            <div class="form-row row">
                <div class="form-group" :class="{ 'error': errors['b_number_address'] }" style="width: 100%">
                    <input @focus="$emit('errorCleaningFocus', 'b_number_address')" class="form-control custom-input"
                        placeholder="..." v-model="deliveryInfo.b_number" name="build_number">
                    <p class="help-text" v-for="( err, key ) in  errors['b_number_address']" :key="key">{{ err }}</p>
                </div>
            </div>

            <label class="label-custom">
                {{ $static('order.floor', 'Поверх') }}<span>*</span>
            </label>
            <div class="form-row row">
                <div class="form-group" :class="{ 'error': errors['delivery_floor'] }" style="width: 100%">
                    <input @focus="$emit('errorCleaningFocus', 'delivery_floor')" class="form-control custom-input"
                        placeholder="..." v-model="deliveryInfo.floor">
                    <p class="help-text" v-for="( err, key ) in  errors['delivery_floor']" :key="key">{{ err }}</p>
                </div>
            </div>

            <label class="label-custom">
                {{ $static('order.apartment', 'Номер квартири') }}<span>*</span>
            </label>
            <div class="form-row row">
                <div class="form-group" :class="{ 'error': errors['delivery_apartment'] }" style="width: 100%">
                    <input @focus="$emit('errorCleaningFocus', 'delivery_apartment')" class="form-control custom-input"
                        placeholder="..." v-model="deliveryInfo.apartment">
                    <p class="help-text" v-for="( err, key ) in  errors['delivery_apartment']" :key="key">{{ err }}</p>
                </div>
            </div>
        </template>

        <template v-else-if="deliveryInfo && deliveryInfo.manifest">
            <template v-if="deliveryInfo.manifest.city">
                <label class="label-custom">
                    {{ $static('order.city', 'Місто') }}<span>*</span>
                </label>
                <div class="form-row row">
                    <div class="form-group" :class="{ 'error': errors['delivery_city'] }" style="width: 100%">
                        <input @focus="$emit('errorCleaningFocus', 'delivery_city')" class="form-control custom-input"
                            placeholder="..." v-model="deliveryInfo.city">
                        <p class="help-text" v-for="( err, key ) in  errors['delivery_city']" :key="key">{{ err }}</p>
                    </div>
                </div>
            </template>

            <template v-if="deliveryInfo.manifest.address">
                <label class="label-custom">
                    {{ $static('order.address', 'Адреса') }}<span>*</span>
                </label>
                <div class="form-row row">
                    <div class="form-group" :class="{ 'error': errors['delivery_address'] }" style="width: 100%">
                        <input @focus="$emit('errorCleaningFocus', 'delivery_address')" class="form-control custom-input"
                            placeholder="..." v-model="deliveryInfo.address" name="address">
                        <p class="help-text" v-for="( err, key ) in  errors['delivery_address']" :key="key">{{ err }}</p>
                    </div>
                </div>
            </template>


            <template v-if="deliveryInfo.manifest.zip">
                <label class="label-custom">
                    {{ $static('order.zip', 'Zip') }}<span>*</span>
                </label>
                <div class="form-row row">
                    <div class="form-group" :class="{ 'error': errors['delivery_zip'] }" style="width: 100%">
                        <input @focus="$emit('errorCleaningFocus', 'delivery_zip')" class="form-control custom-input"
                            placeholder="..." v-model="deliveryInfo.zip" name="zip">
                        <p class="help-text" v-for="( err, key ) in  errors['delivery_zip']" :key="key">{{ err }}</p>
                    </div>
                </div>
            </template>

            <template v-if="deliveryInfo.manifest.warehouse">
                <label class="label-custom">
                    {{ $static('order.warehouse', 'Відділення') }}<span>*</span>
                </label>
                <div class="form-row row">
                    <div class="form-group" :class="{ 'error': errors['delivery_warehouse'] }" style="width: 100%">
                        <input @focus="$emit('errorCleaningFocus', 'delivery_warehouse')" class="form-control custom-input"
                            placeholder="..." v-model="deliveryInfo.warehouse" name="warehouse">
                        <p class="help-text" v-for="( err, key ) in  errors['delivery_warehouse']" :key="key">{{ err }}
                        </p>
                    </div>
                </div>
            </template>

            <template v-if="deliveryInfo.manifest.floor">
                <label class="label-custom">
                    {{ $static('order.floor', 'Поверх') }}<span>*</span>
                </label>
                <div class="form-row row">
                    <div class="form-group" :class="{ 'error': errors['delivery_floor'] }" style="width: 100%">
                        <input @focus="$emit('errorCleaningFocus', 'delivery_floor')" class="form-control custom-input"
                            placeholder="..." v-model="deliveryInfo.floor">
                        <p class="help-text" v-for="( err, key ) in  errors['delivery_floor']" :key="key">{{ err }}</p>
                    </div>
                </div>
            </template>

            <template v-if="deliveryInfo.manifest.apartment">
                <label class="label-custom">
                    {{ $static('order.apartment', 'Номер квартири') }}<span>*</span>
                </label>
                <div class="form-row row">
                    <div class="form-group" :class="{ 'error': errors['delivery_apartment'] }" style="width: 100%">
                        <input @focus="$emit('errorCleaningFocus', 'delivery_apartment')" class="form-control custom-input"
                            placeholder="..." v-model="deliveryInfo.apartment">
                        <p class="help-text" v-for="( err, key ) in  errors['delivery_apartment']" :key="key">{{ err }}
                        </p>
                    </div>
                </div>
            </template>

        </template>
    </div>
</template>

<script>
import SearchSelect from "./SearchSelect.vue"

export default {
    name: 'OrderDelivery',
    components: { SearchSelect },
    props: ["delivery", "errors"],
    emits: ['errorCleaningFocus'],
    computed: {
        deliveryInfo() {
            return this.$store.getters.getDeliveryInfo;
        }
    },
    methods: {
        updateCityInDeliveryInfo(result) {

            const updatedDeliveryInfo = { ...this.deliveryInfo, city: result };
            this.$store.commit('setDeliveryInfo', updatedDeliveryInfo);
        },
        updateWarehouseInDeliveryInfo(result) {

            const updatedDeliveryInfo = { ...this.deliveryInfo, warehouse: result };
            this.$store.commit('setDeliveryInfo', updatedDeliveryInfo);
        },
        updateAddressInDeliveryInfo(result) {
            const updatedDeliveryInfo = { ...this.deliveryInfo, address: result };
            this.$store.commit('setDeliveryInfo', updatedDeliveryInfo);
        },
        areaSearch(query) {
            // Не используется на данный момент
            return fetch('/public/nova/areas', { method: 'POST', body: { search: query } }).then(response => response.json()).then(data => {
                if (data.result) {
                    return data.result
                }
                return []
            })
        },
        areaCity(query) {
            let search = { search: query };
            // if (this.delivery.area && !query) {
            //   search = {search: this.delivery.area.Ref, ref: false}
            // }

            return fetch('/public/nova/cities', { method: 'POST', body: JSON.stringify(search) }).then(response => response.json()).then(data => {
                if (data.result) {
                    return data.result
                }
                return []
            })
        },
        areaWarehouse(query) {
            if (this.deliveryInfo.city) {
                let search = { CityRef: this.deliveryInfo.city.Ref };
                if (query) {
                    search.WarehouseId = query
                }

                return fetch('/public/nova/warehouse', { method: 'POST', body: JSON.stringify(search) }).then(response => response.json()).then(
                    data => {
                        if (data.result) {
                            return data.result
                        }
                        return []
                    })
            }
            return new Promise(resolve => resolve([]))
        },
        areaStreet(query) {
            if (this.deliveryInfo.city) {
                let search = { CityRef: this.deliveryInfo.city.Ref };
                if (query) {
                    search.FindByString = query
                }

                return fetch('/public/nova/street', { method: 'POST', body: JSON.stringify(search) }).then(response => response.json()).then(
                    data => {
                        if (data.result) {
                            return data.result
                        }
                        return []
                    })
            }
            return new Promise(resolve => resolve([]))

        },
    }
}
</script>

